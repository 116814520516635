.viewAPI2 {
  font-family: PingFangSC-Regular;
  /*.bodyStyleDivStyle {*/
  /*    border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  /*    padding-bottom: 0.1rem;*/
  /*}*/
  /* @import "../../assets/css/puliceStyle.css"; */
}
.viewAPI2 .bodyStyleDivStyleP {
  margin: 0.56rem 0 0.2rem 0;
}
.viewAPI2 .body1StyleInputStyle {
  border: 0;
  font-size: 0.28rem;
  width: 80%;
  line-height: 0.7rem;
}
.viewAPI2 .body1StyleInputStyle::-webkit-input-placeholder,
.viewAPI2 .body1StyleInputStyle:-moz-placeholder,
.viewAPI2 .body1StyleInputStyle::-moz-placeholder,
.viewAPI2 .body1StyleInputStyle:-ms-input-placeholder,
.viewAPI2 .body1StyleInputStyle::-ms-input-placeholder,
.viewAPI2 .body1StyleInputStyle::placeholder {
  color: #FFFFFF !important;
}
.viewAPI2 .bottom2DivStyle {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
}
.viewAPI2 .pc_in {
  position: relative;
  float: left;
  width: 6.7rem;
  height: 1.12rem;
  border: none;
}
.viewAPI2 .pc_in .divbox {
  display: flex;
  width: 100%;
  height: 1.12rem;
  overflow: hidden;
}
.viewAPI2 .pc_in .divbox span {
  flex: 1;
  height: 100%;
  border-bottom: #e6e8ed solid 1px;
  border-right: none;
  margin: 0 0.16rem;
}
.viewAPI2 .pc_in .divbox span.bl {
  border-color: #000;
}
.viewAPI2 .pc_in p {
  position: absolute;
  width: 100%;
  height: 1.12rem;
  line-height: 1.12rem;
  top: 0;
  left: 0;
  background: none;
  overflow: hidden;
}
.viewAPI2 .pc_in p input {
  float: left;
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  margin-top: 0.2rem;
  letter-spacing: 0.84rem;
  background: none;
  text-indent: 0.4rem;
  font-size: 0.48rem;
}
.viewAPI2 .getCodeBtn {
  color: #EFBA0D;
  font-size: 0.24rem;
  float: right;
  border: none;
  background: none;
}
.viewAPI2 .bottom2PStyle {
  font-size: 0.24rem;
  font-weight: 500;
  color: #666666;
  margin: 0 0.4rem;
  margin-bottom: 0.2rem;
}
.viewAPI2 .bottom3PStyle {
  color: #0f0f0f;
  margin: 0.3rem;
  font-size: 0.3rem;
}
.viewAPI2 .body1StyleSPan {
  color: #ff4b04;
}
.viewAPI2 .body1StylepFont {
  color: #666666;
  font-size: 0.28rem;
  margin-bottom: 0.51rem;
}
.viewAPI2 .body1StylepFont .mainFontLightColor {
  color: #333333 !important;
}
.viewAPI2 .body1StyleInputStyle {
  font-size: 0.28rem;
  color: #333333;
  border-bottom: 0.01rem solid #D3D9DE;
  width: 100%;
}
.viewAPI2 input::-webkit-input-placeholder {
  /* 修改字体颜色 */
  color: #999999;
  /* 修改字号，默认继承input */
  font-size: 12px;
}
.viewAPI2 .bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}
.viewAPI2 .bodyStyleDivStyle {
  line-height: 0.4rem;
  margin-bottom: 0.2rem;
}
.viewAPI2 .bodyStyleDivStyle .bd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #EDEDED;
}
.viewAPI2 .bodyStyleDivStyle .bd .inp {
  border: none;
  color: #333333;
  font-size: 0.28rem;
}
.viewAPI2 .bodyStyleDivStyle .bd .button {
  font-size: 0.24rem;
  color: #EFBA0D;
}
.viewAPI2 .bodyStyleDivStyle1 {
  margin-top: 0.6rem;
}
.viewAPI2 .bodyStyleDivStyle1 .mainFontLightColor {
  font-size: 0.28rem;
  color: #333333 !important;
  font-weight: bold;
}
.viewAPI2 .bodyStyleDivStyle2 {
  margin-top: 0.2rem;
}
.viewAPI2 .bodyStyleDivStyle2 .mainFontLightColor {
  font-size: 0.28rem;
  color: #333333 !important;
  font-weight: bold;
}
.viewAPI2 .body1Style {
  font-size: 0.28rem;
  padding: 0.3rem 0.4rem;
}
.viewAPI2 .bottom1 {
  padding-bottom: 0.6rem;
}
.viewAPI2 .bottom1 .queren {
  width: 6.7rem;
  height: 0.9rem;
  margin: 0 auto 0.2rem;
  background-color: #FAD538;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.9rem;
  text-align: center;
  border-radius: 0.16rem;
}
.viewAPI2 .help-btn {
  color: #29aae1;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
.content {
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  margin-top: 0.2rem;
  height: calc(100vh - 1.1rem);
}
.btnStyle {
  width: 3rem;
  height: 0.9rem;
  background-color: #FAD538;
  border-radius: 0.16rem;
  line-height: 0.9rem;
  font-size: 0.32rem;
  color: #333333;
}
